import React from 'react'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Image from '../components/image'
import { Box, Row, Column } from '../components/ui'
import Map from '../components/map'

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin: 4px 0 4px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  font-family: sans-serif;
  font-size: 12px;
  line-height: normal;
  box-sizing: border-box;
  border-radius: 2px;
`
const Textarea = styled.textarea`
  width: 100%;
  padding: 12px;
  margin: 6px 0 4px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  font-family: sans-serif;
  font-size: 12px;
  line-height: normal;
  box-sizing: border-box;
  border-radius: 2px;

  min-height: 100px;
  resize: vertical;
`

const SubmitButton = styled.button`
  display: inline-block;
  width: auto;
  height: auto;
  padding: 1em 2.5em;
  color: #fff;
  background-color: #272727;
  border-width: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 1em;
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  appearance: none;
  font-size: 14px;
  text-transform: none;
`

const IndexPage = () => (
  <Layout routePath="contact-us">
    <SEO title="Contact Us" />

    <Box mt={5} fontSize={32} fontWeight={300}>
      Contact
    </Box>

    <Box width={1} my={4} borderBottom="1px solid #eee" />

    <Row flexDirection={['column', 'row']}>
      <Column width={[1, 1 / 2]}>
        <Row width={1} mb={3}>
          <Column
            width={1 / 2}
            css={{
              lineHeight: '140%',
              fontSize: 14,
              a: { color: 'inherit', textDecoration: 'none' },
            }}
          >
            <Box my={3} fontSize={22}>
              Pro Pallet
            </Box>

            <Box mb={3}>
              151 12th Ave NW
              <br />
              West Fargo, ND 58078
            </Box>

            <Box>
              <a href="tel:1-701-281-0431">1-701-281-0431</a>
              <br />
              <a href="mailto:sales@propalletnd.com">sales@propalletnd.com</a>
            </Box>
          </Column>

          <Box width={1 / 2}>
            <Image filename="shop.jpg" />
          </Box>
        </Row>

        <Box width={1} height={300}>
          <Map />
        </Box>
      </Column>

      <Box px={3} />

      <Box width={[1, 1 / 2]}>
        <Box
          width={1}
          css={{
            label: {
              color: '#666',
              fontSize: '13px',
            },
          }}
        >
          <Box
            fontSize={14}
            mt={[3, 0]}
            mb={4}
            css={{ lineHeight: '140%', a: { color: 'inherit', textDecoration: 'none' } }}
          >
            The quickest method to get in contact, is to call us at{' '}
            <a href="tel:1-701-281-0431">1-701-281-0431</a>. If you have a general inquiry, you may
            e-mail us, or use the contact form below, and we will respond as soon as possible.
          </Box>

          <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="bot-field" />

            <p>
              <label>
                Name <Input type="text" name="name" placeholder="Name" />
              </label>
            </p>

            <p>
              <label>
                Contact <Input type="text" name="email" placeholder="Phone / Email" />
              </label>
            </p>

            <p>
              <label>
                Message <Textarea name="message" placeholder="Message"></Textarea>
              </label>
            </p>

            <p>
              <SubmitButton type="submit">Submit</SubmitButton>
            </p>
          </form>
        </Box>
      </Box>
    </Row>
  </Layout>
)

export default IndexPage
